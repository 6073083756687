import { Wrapper, Grid, Box, Stack, ListDivider, NewsListItem, TextButton } from 'designsystem';
import Link from 'next/link';
import { FormattedMessage, useIntl } from 'react-intl';
import { getMetaForArticle, useGetCmsImageProps } from 'shared';
import slugify from 'slugify';
import { useArticlesOverviewQuery, useNewsPathQuery } from '../gql/cms';
import siteHandleByLocale from '../utils/siteHandleByLocale';

interface Props {
    amount?: number;
}

const HomeRecentNews: React.FC<Props> = ({ amount = 3 }) => {
    const intl = useIntl();
    const { locale } = intl;
    const getImgProps = useGetCmsImageProps();
    const { data } = useArticlesOverviewQuery({
        site: siteHandleByLocale[locale],
        limit: amount,
    });
    const articles = data?.festivalNewsArticlesEntries;
    const { data: newsPathData } = useNewsPathQuery({});
    const newsOverview = newsPathData?.entry;

    return (
        articles?.length > 0 && (
            <Wrapper>
                <Grid>
                    <Box gridColumn={['1 / -1', null, null, null, '3 / -3']}>
                        <Stack spacing={[6, null, 9]} divider={<ListDivider />} mb={[6, null, 9]}>
                            {articles?.map(article => (
                                <Link href={article.slug ? `/news/${slugify(article.slug)}` : '/404'} key={article.id}>
                                    <NewsListItem
                                        title={article.title}
                                        meta={getMetaForArticle(article, intl)}
                                        image={getImgProps(article.headerImage?.[0])}
                                    />
                                </Link>
                            ))}
                        </Stack>

                        {newsOverview && (
                            <Box>
                                <Link href={newsOverview.uri ?? '/404'}>
                                    <TextButton size="s" as="span">
                                        <FormattedMessage defaultMessage="Alle artikelen" id="recent-news.show-all" />
                                    </TextButton>
                                </Link>
                            </Box>
                        )}
                    </Box>
                </Grid>
            </Wrapper>
        )
    );
};

export default HomeRecentNews;
